import { createRouter, createWebHashHistory } from 'vue-router'
import { systemStore } from '@/store/system.js'

const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            lightMenu: 'home', // 导航栏高亮菜单
            pageTitle: '桂泽仪器官网' // 显示默认页面标题
        },
        component: () => import('../pages/home/index.vue')
    },
    {
        path: '/aboutus',
        name: 'aboutus',
        redirect: '/company',
        component: () => import('../pages/aboutus/index.vue'),
        children: [
            {
                path: '/company',
                name: 'company',
                meta: {
                    lightMenu: 'company',
                    text: '企业介绍',
                    pageTitle: '企业介绍——桂泽仪器官网'
                },
                component: () => import('../pages/aboutus/company.vue')
            },
            {
                path: '/contact',
                name: 'contact',
                meta: {
                    lightMenu: 'contact',
                    text: '联系我们',
                    pageTitle: '联系我们——桂泽仪器官网'
                },
                component: () => import('../pages/aboutus/contact.vue')
            },
            {
                path: '/join',
                name: 'join',
                meta: {
                    lightMenu: '',
                    text: '加入我们',
                    pageTitle: '加入我们——桂泽仪器官网'
                },
                component: () => import('../pages/aboutus/join.vue')
            }
        ]
    },
    {
        path: '/category/:id',
        name: 'category',
        meta: {
            lightMenu: 'home'
        },
        component: () => import('../pages/category/index.vue')
    },
    {
        path: '/product/:id',
        name: 'product',
        meta: {
            lightMenu: 'home'
        },
        component: () => import('../pages/product/index.vue')
    },
    {
        path: '/detail/:id',
        name: 'detail',
        meta: {
            lightMenu: 'home'
        },
        component: () => import('../pages/detail/index.vue')
    },
    {
        path: '/cart',
        name: 'cart',
        meta: {
            lightMenu: '',
            pageTitle: '桂泽仪器官网'
        },
        component: () => import('../pages/cart/index.vue')
    },
    {
        path: '/order',
        name: 'order',
        meta: {
            lightMenu: '',
            isAuth: true,
            pageTitle: '桂泽仪器官网'
        },
        component: () => import('../pages/order/index.vue')
    },
    {
        path: '/ordersubmit',
        name: 'orderSubmit',
        meta: {
            lightMenu: '',
            isAuth: true,
            pageTitle: '桂泽仪器官网'
        },
        component: () => import('../pages/ordersubmit/index.vue')
    },
    {
        path: '/orderinfo',
        name: 'orderInfo',
        meta: {
            lightMenu: '',
            isAuth: true,
            pageTitle: '桂泽仪器官网'
        },
        component: () => import('../pages/orderinfo/index.vue')
    },
    {
        path: '/mypage',
        name: 'mypage',
        meta: {
            lightMenu: '',
            isAuth: true,
            pageTitle: '桂泽仪器官网'
        },
        component: () => import('../pages/mypage/index.vue')
    },
    {
        path: '/compare',
        name: 'compare',
        meta: {
            lightMenu: '',
            pageTitle: '桂泽仪器官网'
        },
        component: () => import('../pages/compare/index.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // 始终滚动到顶部
        return { top: 0 }
    }
})

router.beforeEach((to, from, next) => {
    const pageTitle = to.meta?.pageTitle
    if (pageTitle) {
        document.title = pageTitle
    }
    const isAuth = to.meta?.isAuth
    if (isAuth && !localStorage.getItem('TOKEN')) {
        const systemStoreInfo = systemStore()
        systemStoreInfo.loginType = 1
        if (!from.name) {
            next('/')
        }
    } else {
        next()
    }
})

export default router
