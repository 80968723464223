<template>
  <Transition name="down">
    <div class="message" :style="style[type]" v-show="visible">
<!--      <i class="iconfont" :class="[style[type].icon]"></i>-->
      <span class="text">{{text}}</span>
    </div>
  </Transition>
</template>
<script setup>
import { ref, onMounted } from 'vue'

// eslint-disable-next-line no-undef, no-unused-vars
const props = defineProps({
    type: {
        type: String,
        default: 'warning'
    },
    text: {
        type: String,
        default: ''
    }
})
const style = {
    warning: {
        icon: 'icon-warning',
        color: '#E6A23C',
        backgroundColor: 'rgb(253, 246, 236)',
        borderColor: 'rgb(250, 236, 216)'
    },
    error: {
        icon: 'icon-shanchu',
        color: '#F56C6C',
        backgroundColor: 'rgb(254, 240, 240)',
        borderColor: 'rgb(253, 226, 226)'
    },
    success: {
        icon: 'icon-queren2',
        color: '#67C23A',
        backgroundColor: 'rgb(240, 249, 235)',
        borderColor: 'rgb(225, 243, 216)'
    }
}
// 控制元素显示隐藏
const visible = ref(false)
onMounted(() => {
    visible.value = true
})
</script>
<style scoped lang="scss">
.message {
  width: 300px;
  height: 50px;
  position: fixed;
  z-index: 9999;
  left: 50%;
  margin-left: -150px;
  top: 85px;
  line-height: 48px;
  padding: 0 25px;
  border: 1px solid #e4e4e4;
  background: #f5f5f5;
  color: #999;
  border-radius: 4px;
  i {
    margin-right: 4px;
    // vertical-align: middle;
  }
  .text {
    // vertical-align: middle;
  }
}
.down-enter-from {
  transform: translateY(-70px);
  opacity: 0;
}
.down-enter-to {
  transform: translateY(0px);
}
.down-enter-active,
.down-leave-active {
  transition: all 0.3s;
}
.down-leave-from {
  transform: translateY(0px);
  opacity: 1;
}
.down-leave-to {
  transform: translateY(-70px);
  opacity: 0;
}
</style>
