import Message from '@/components/message/index.js'
// import { sm2 } from 'sm-crypto'

// 密码加密
export function cryptoCode (code) {
    return code
    // const key = 'MFkwEwYHKoZIzj0CAQYIKoEcz1UBgi0DQgAEGr1zdkcjQA+UNz4ixPWyRkwOYACpCp6dGyPMLokPpzVaQPOD3kkd77V2I9GcESdJW21t59A5HVEMQC3XWn0IhA=='
    // const publicKey = '04' + key
    // const password = sm2.doEncrypt(code, publicKey, 1)
    // return password
}

// 表单非空校验
export const verifyForm = (arr, obj) => {
    return new Promise((resolve, reject) => {
        for (const i of arr) {
            if (!obj[i.key]) {
                Message({
                    type: 'warning',
                    text: i.message
                })
                return
            }
        }
        resolve()
    })
}

// 手机号脱敏
export const formatPhone = (phone) => {
    const reg = /^(1[3-9][0-9])\d{4}(\d{4}$)/
    return phone ? phone.replace(reg, '$1****$2') : '--'
}

// 防抖
export function debounce(fn, wait = 200) {
    let timer
    return function () {
        const context = this
        const args = arguments
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
            fn.apply(context, args)
        }, wait)
    }
}

// 节流
export function throttle(fn, wait = 200) {
    let last, timer, now
    return function() {
        now = Date.now()
        if (last && now - last < wait) {
            clearTimeout(timer)
            timer = setTimeout(function() {
                last = now
                fn.call(this, ...arguments)
            }, wait)
        } else {
            last = now
            fn.call(this, ...arguments)
        }
    }
}
