<template>
  <footer>
    <div class="feature" :style="{background: currentPath=='home' ? '#FFFFFF' : '#F6F6F6'}">
      <div class="feature-info container">
        <div class="feature-item">
          <img class="icon" src="@/assets/icon/category.png">
          <div>{{ $t('footer.category') }}</div>
        </div>
        <div class="divide"></div>
        <div class="feature-item">
          <img class="icon" src="@/assets/icon/quality.png">
          <div>{{ $t('footer.quality') }}</div>
        </div>
        <div class="divide"></div>
        <div class="feature-item">
          <img class="icon" src="@/assets/icon/transport.png">
          <div>{{ $t('footer.transport') }}</div>
        </div>
        <div class="divide"></div>
        <div class="feature-item">
          <img class="icon" src="@/assets/icon/aftersales.png">
          <div>{{ $t('footer.aftersales') }}</div>
        </div>
      </div>
    </div>
    <div class="site">
      <div class="container">
        <div class="flex">
          <div class="flex-1">
            <div class="title">
              <span>{{ $t('footer.contact') }}</span>
              <span style="color:#EAE7E7">·CONTACT</span>
            </div>
            <div class="flex">
              <div v-for="(item, index) in dataList" :key="index" class="address">
                <div class="address-name">
                  <img class="icon" src="@/assets/icon/address.png">
                  <div>{{ item.areaName }}</div>
                </div>
                <div class="address-person" v-for="(it, idx) in item.contactList" :key="idx">
                  <div class="mr-40 flex-align-center">
                    <img class="icon" src="@/assets/icon/person.png">
                    <div>{{ it.contactName }}</div>
                  </div>
                  <div class="mr-40 flex-align-center">
                    <img class="icon" src="@/assets/icon/phone.png">
                    <div>{{ it.contactMobile }}</div>
                  </div>
                  <div class="flex-align-center">
                    <img class="icon" src="@/assets/icon/email.png">
                    <div>{{ it.contactEmail }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="title">{{ $t('footer.wechat') }}</div>
            <img class="qrcode" :src="websiteConfig.website_config_company_qr_code">
          </div>
        </div>
        <div class="copyright">{{ websiteConfig.website_config_company_icp }}</div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { getContactList } from '@/api/common'
import { systemStore } from '@/store/system.js'

const route = useRoute()
const dataList = ref([])

const currentPath = computed(() => {
    return route.name
})
const websiteConfig = computed(() => {
    return systemStore().websiteConfig
})

const getList = () => {
    getContactList().then((data) => {
        dataList.value = data
    })
}

getList()
</script>

<style lang="scss" scoped>
.feature {
  width: 100%;
  height: 92px;
  background: #F6F6F6;
  &-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  &-item {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    .icon {
      width: 36px;
      height: 36px;
      margin-right: 16px;
    }
  }
  .divide {
    width: 1px;
    height: 60px;
    background: #D4D4DA;
  }
}
.site {
  width: 100%;
  padding: 58px 0 10px 0;
  background: #414142;
  color: #FFFFFF;
  .title {
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 500;
    line-height: 33px;
  }
}
.address {
  &-name {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 38px;
    .icon {
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }
  }
  &-person {
    display: flex;
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 38px;
    .mr-40 {
      margin-right: 40px;
    }
    .icon {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }
  }
}
.address+.address {
  margin-left: 30px;
  padding-left: 30px;
  border-left: 1px solid #8C8D8E;
}
.qrcode {
  width: 130px;
  height: 130px;
}
.copyright {
  text-align: center;
  margin-top: 58px;
  font-size: 14px;
  font-weight: 500;
  color: #767777;
  line-height: 38px;
}
</style>
