<template>
  <nav class="head">
    <div class="headbar container">
      <router-link to="/">
        <img
          class="logo"
          :src="logo"
          :alt="$t('common.name')"
        />
      </router-link>
      <div
        v-for="(item, index) in menuList"
        :key="index"
        :class="[{'menu_act': lightPath === item.pathName}, {'menu_sub': item.hasSub}]"
        class="menu"
        @mouseenter="subnavHidden = false"
      >
        <router-link
          :to="item.path"
          class="menu-link"
          @click="subnavHidden = true"
          >{{ item.name }}</router-link
        >
        <div v-if="item.hasSub" class="subnav" :class="subnavHidden? 'hidden' : ''">
          <div class="subnav-wrap container">
            <router-link
              v-for="(item, index) in productList"
              :key="index"
              :to="`/category/${item.categoryId}`"
              class="subnav-item"
              @click="subnavHidden = true"
            >
              <div class="name">{{ item.categoryName }}</div>
              <img class="image" :src="item.coverImg">
            </router-link>
          </div>
        </div>
      </div>
      <div class="search">
        <input type="password" style="display: none;">
        <input
            v-model="keyword"
            class="search-input"
            name="search-input"
            :placeholder="$t('home.search')"
            @input="searchInput"
            @focus="searchFocus"
            @blur="searchBlur"
        />
        <img
          class="search-icon"
          src="@/assets/icon/search.png"
        />
        <div v-show="panelShowType">
          <div class="search-mask"></div>
          <div class="panel">
            <div v-show="panelShowType === 1" class="panel-header">
              <div>搜索历史</div>
              <div class="clear" @click="clearHistoryData">清空</div>
            </div>
            <ul class="panel-list">
              <li
                v-for="(item, index) in searchList"
                :key="index"
                class="item"
                @click="goTarget(item)"
              >{{ item.title }}</li>
              <li v-show="!searchList.length" class="empty">无记录</li>
            </ul>
            </div>
        </div>
      </div>
      <div class="flex-1"></div>
      <div v-if="token" class="user">
        <div class="user-name">
          <div>{{ formatPhone(userInfo.mobile) }}</div>
          <img class="i-arrow" src="@/assets/icon/arrow-down.png">
        </div>
        <div class="user-wrap">
          <div class="user-funcs">
            <div class="funcs" @click="goCenter">{{ $t('home.userCenter') }}</div>
            <div class="funcs" @click="systemStoreInfo.loginType=3">{{ $t('home.modifyPassword') }}</div>
            <div class="funcs" @click="loginOut">{{ $t('home.loginOut') }}</div>
          </div>
        </div>
      </div>
      <div v-else class="btn">
        <span class="btn-login" @click="systemStoreInfo.loginType=1">{{ $t('common.login') }}</span>
        <span>|</span>
        <span class="btn-register" @click="systemStoreInfo.loginType=2">{{ $t('common.register') }}</span>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { systemStore } from '@/store/system.js'
import { userStore } from '@/store/user.js'
import { getCategoryPage, searchProduct } from '@/api/product'
import { formatPhone, debounce } from '@/utils/common'
import Message from '@/components/message/index.js'

const systemStoreInfo = systemStore()
const userStoreRefs = storeToRefs(userStore()) // 1登录 2注册
const userInfo = userStoreRefs.userInfo
const token = userStoreRefs.token
const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const subnavHidden = ref(false)
const menuList = [
    { name: t('home.product'), path: '/', pathName: 'home', hasSub: true },
    { name: t('home.company'), path: '/company', pathName: 'company' },
    { name: t('home.contact'), path: '/contact', pathName: 'contact' }
]
const keyword = ref('')
const panelShowType = ref(0) // 搜索面板展示类型：0不展示 1历史记录 2搜索结果
const searchList = ref([])
const productList = ref([])

userStore().getHistoryData()
const historyList = computed(() => {
    return userStore().historyList
})
const lightPath = computed(() => {
    return route.meta.lightMenu
})
const logo = computed(() => {
    return systemStore().websiteConfig.website_config_company_logo
})

const searchInput = debounce(() => {
    if (!keyword.value) {
        panelShowType.value = 1
        searchList.value = historyList.value
        return
    }
    panelShowType.value = 2
    searchProduct({
        keyword: keyword.value
    }).then((data) => {
        searchList.value = data
    })
}, 500)
const searchFocus = () => {
    if (keyword.value) {
        panelShowType.value = 2
    } else {
        panelShowType.value = 1
        searchList.value = historyList.value
    }
}
const searchBlur = () => {
    setTimeout(() => {
        panelShowType.value = 0
    }, 300)
}
const goTarget = (item) => { // type 1：分类，11：产品，12：规格：13：型号
    if (item.type === 1) {
        if (item.target.isLeaf) { // 无下级类目
            router.push({ path: `/product/${item.target.categoryId}` })
        } else { // 有下级类目
            router.push({ path: `/category/${item.target.categoryId}` })
        }
    } else if (item.type === 11) {
        router.push({ path: `/detail/${item.target.productId}` })
    } else if (item.type === 12) {
        router.push({ path: `/detail/${item.target.productId}` })
    } else if (item.type === 13) {
        router.push({
            path: `/detail/${item.target.productId}`,
            query: {
                model: item.title
            }
        })
    }
    setHistoryData(item)
}
// 缓存搜索记录
const setHistoryData = (item) => {
    const index = historyList.value.findIndex(it => it.title === item.title)
    const arr = [...historyList.value]
    if (index > -1) {
        arr.splice(index, 1)
        arr.unshift(item)
    } else {
        if (arr.length < 10) {
            arr.unshift(item)
        } else {
            arr.pop()
        }
    }
    localStorage.setItem('HISTORYLIST', JSON.stringify(arr))
    userStore().getHistoryData()
}
const clearHistoryData = () => {
    localStorage.removeItem('HISTORYLIST')
    userStore().getHistoryData()
}
const getProductList = () => {
    getCategoryPage({
        pageIndex: 1,
        pageSize: 20
    }).then((data) => {
        productList.value = data.datas
    })
}
const goCenter = () => {
    router.push({ path: '/mypage' })
}
const loginOut = () => {
    token.value = null
    userInfo.value = {}
    userStoreRefs.cartGoodsNum.value = 0
    localStorage.removeItem('TOKEN')
    localStorage.removeItem('USERINFO')
    router.push({ path: '/' })
    Message({
        type: 'success',
        text: '退出成功'
    })
}

getProductList()
</script>

<style lang="scss" scoped>
.head {
  position: fixed;
  top: 0;
  width: 100%;
  height: 64px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
  z-index: 9;
}
.headbar {
  display: flex;
  align-items: center;
}
.logo {
  width: 126px;
  height: 46px;
  margin-right: 80px;
}
.menu {
  height: 100%;
  font-weight: 500;
  line-height: 64px;
  margin-left: 14px;
  &:hover .menu-link {
    color: #D9312D;
  }
  &-link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 23px;
  }
}
.menu_act {
  background: #D9312D;
  .menu-link {
    color: #ffffff !important;
  }
}
.subnav {
  display: none;
  position: absolute;
  left: 0;
  top: 64px;
  width: 100%;
  padding: 30px 0;
  background: #ffffff;
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.15);
  animation: subnavMove 0.25s ease-in-out;
  z-index: -1;
  &-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
  }
  &-item {
    display: flex;
    align-items: center;
    width: 288px;
    padding: 6px 6px 6px 20px;
    background: #FFFFFF;
    border: 1px solid #E5E5EA;
    transition: all 0.3s;
    cursor: pointer;
    .name {
      flex: 1;
      font-size: 14px;
      line-height: 20px;
    }
    .image {
      width: 136px;
      height: 100px;
    }
    &:hover {
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);
      .name {
        color: #D9312D;
      }
    }
  }
}
.menu_sub:hover .subnav {
  display: block;
  transform: translateY(0);
}
.hidden {
  display: none !important;
}
@keyframes subnavMove {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.search {
  position: relative;
  display: flex;
  align-items: center;
  width: 316px;
  height: 40px;
  margin-left: 36px;
  padding: 0 14px;
  border-radius: 20px;
  border: 1px solid #D9312D;
  font-size: 16px;
  &-input {
    flex: 1;
    border: none;
  }
  &-icon {
    width: 24px;
    height: 24px;
    pointer-events: none;
    // cursor: pointer;
  }
  &-mask {
    position: fixed;
    left: 0;
    top: 64px;
    bottom: 0;
    right: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.3);
  }
}
.panel {
  position: absolute;
  top: 52px;
  left: 0;
  width: 316px;
  max-height: 340px;
  overflow-y: auto;
  z-index: 10;
  padding: 10px;
  border-radius: 3px;
  background: #ffffff;
  box-shadow: 2px 2px 3px rgba(0,0,0,0.3);
  font-size: 14px;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid #ebebeb;
    padding: 0 10px;
    font-weight: 500;
    .clear {
      color: #999999;
      font-weight: normal;
      cursor: pointer;
    }
  }
  &-list {
    .item {
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .item:hover {
      background: #EBEBEB;
      color: #D9312D;
    }
    .empty {
      text-align: center;
      color: #999;
      line-height: 40px;
    }
  }
}
.user {
  position: relative;
  &-name {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: #D9312D;
    .i-arrow {
      width: 16px;
      height: 16px;
      margin-left: 12px;
    }
  }
  &-wrap {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    padding-top: 46px;
  }
  &-funcs {
    width: 158px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
    text-align: center;
    font-size: 16px;
    color: #111111;
    line-height: 50px;
    cursor: pointer;
    .funcs:hover {
      background: #EDEDF1;
    }
  }
  &:hover &-wrap {
    display: block;
  }
}
.btn {
  text-align: right;
  font-size: 16px;
  font-weight: 500;
  &-login {
    color: #D9312D;
    margin-right: 16px;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
  &-register {
    margin-left: 16px;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
