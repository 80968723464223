import request from '@/utils/request'

// 获取手机验证码
export function getMobileCode (data) {
    return request({
        url: '/guize-instrument-server/customer/auth/getMobileCode',
        method: 'post',
        data
    })
}

// 用户注册
export function mobileRegister (data) {
    return request({
        url: '/guize-instrument-server/customer/auth/mobileRegister',
        method: 'post',
        data
    })
}

// 用户登录
export function mobileLogin (data) {
    return request({
        url: '/guize-instrument-server/customer/auth/mobileLogin',
        method: 'post',
        data
    })
}

// 用户重置密码
export function passwordReset (data) {
    return request({
        url: '/guize-instrument-server/customer/auth/resetPassword',
        method: 'post',
        data
    })
}

// 获取个人信息详情
export function getUserDetail (params) {
    return request({
        url: '/guize-instrument-server/customer/user/getDetail',
        method: 'get',
        params
    })
}

// 个人首页信息
export function getUserIndex (params) {
    return request({
        url: '/guize-instrument-server/customer/user/index',
        method: 'get',
        params
    })
}

// 修改个人信息
export function updateUserDetail (data) {
    return request({
        url: '/guize-instrument-server/customer/user/saveCustomer',
        method: 'post',
        data
    })
}

// 获取个人收货列表
export function getAddressList (params) {
    return request({
        url: '/guize-instrument-server/customer/user/getAddressList',
        method: 'get',
        params
    })
}

// 保存收货地址
export function saveAddress (data) {
    return request({
        url: '/guize-instrument-server/customer/user/saveAddress',
        method: 'post',
        data
    })
}

// 删除收货地址
export function deleteAddress (params) {
    return request({
        url: '/guize-instrument-server/customer/user/deleteAddress',
        method: 'get',
        params
    })
}

// 设置默认收货地址
export function setDefaultAddress (params) {
    return request({
        url: '/guize-instrument-server/customer/user/setDefaultAddress',
        method: 'get',
        params
    })
}

// 获取个人发票列表
export function getInvoiceList (params) {
    return request({
        url: '/guize-instrument-server/customer/user/getInvoiceList',
        method: 'get',
        params
    })
}

// 保存发票
export function saveInvoice (data) {
    return request({
        url: '/guize-instrument-server/customer/user/saveInvoice',
        method: 'post',
        data
    })
}

// 删除发票
export function deleteInvoice (params) {
    return request({
        url: '/guize-instrument-server/customer/user/deleteInvoice',
        method: 'get',
        params
    })
}
