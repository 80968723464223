import axios from 'axios'
import Message from '@/components/message/index.js'
import { userStore } from '@/store/user.js'
import { systemStore } from '@/store/system'

class Error {
    /* eslint-disable */
    constructor(code, message, err) {
        this.code = code
        this.message = message
        this.err = err
    }
}

const instance = axios.create({
    baseURL: process.env.BASE_URL,
    timeout: 5000
})

instance.interceptors.request.use(config => {
    const token = window.localStorage.getItem("TOKEN")
    config.headers.Authentication = token
    return config
}, err => {
    return Promise.reject(new Error(505, '系统异常', err))
})

instance.interceptors.response.use(response => {
    if (response.status === 200) {
        const code = response.data.code
        if (code === 200) {
            return Promise.resolve(response.data.data)
        } else {
            Message({
                type: 'warning',
                text: response.data.message
            })
            return Promise.reject(new Error(code, response.data.message))
        }
    } else if (response.status === 401) {
        localStorage.removeItem('TOKEN')
        localStorage.removeItem('USERINFO')
        userStore().userInfo = {}
        userStore().token = null
        systemStore().loginType = 1
        return Promise.reject(new Error(401, '用户登录信息失效，请重新登录~~', err))
    } else {
        Message({
            type: 'error',
            text: '系统异常~~'
        })
        return Promise.reject(new Error(500, '系统异常', err))
    }
}, err => {
    if (err.response.status === 401) {
        localStorage.removeItem('TOKEN')
        localStorage.removeItem('USERINFO')
        userStore().userInfo = {}
        userStore().token = null
        systemStore().loginType = 1
        return Promise.reject(new Error(401, '用户登录信息失效，请重新登录~~', err))
    } else {
        Message({
            type: 'error',
            text: '系统异常~~'
        })
        return Promise.reject(new Error(500, '系统异常', err))
    }
})

export const get = (url, params) => {
    return instance.get(url, { params })
}

export const post = (url, data) => {
    return instance.post(url, data)
}

export default instance
