import { defineStore } from 'pinia'
import { getUserIndex } from '@/api/user'

export const userStore = defineStore('user', {
    state: () => {
        return {
            token: localStorage.getItem('TOKEN') || null,
            userInfo: JSON.parse(localStorage.getItem('USERINFO') || '{}'),
            cartGoodsNum: 0,
            compareList: [],
            compareNum: 0,
            historyList: []
        }
    },
    actions: {
        getUserCommon() {
            if (!this.token) return
            getUserIndex().then((data) => {
                this.cartGoodsNum = data.shoppingCartCount
            })
        },
        getCompareData() {
            this.compareList = JSON.parse(localStorage.getItem('COMPARELIST') || '[]')
            this.compareNum = this.compareList.length
        },
        getHistoryData() {
            this.historyList = JSON.parse(localStorage.getItem('HISTORYLIST') || '[]')
        }
    }
})
