<template>
    <div ref="navBar" class="navbar">
        <NavBar />
    </div>
    <div :style="{'min-height': mainHeight}">
        <router-view :key="routerKey" />
    </div>
    <div ref="footer">
        <Footer />
    </div>
    <SideBar />
    <Login />
</template>

<script setup>
import { ref, onMounted, nextTick, computed } from 'vue'
import { useRoute } from 'vue-router'
import { getByDicId } from '@/api/common'
import { systemStore } from '@/store/system.js'
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import SideBar from '@/components/SideBar.vue'
import Login from '@/components/Login.vue'

const route = useRoute()
const systemStoreInfo = systemStore()
const navBar = ref(null)
const footer = ref(null)
const mainHeight = ref('calc(100vh - 568px)')

const routerKey = computed(() => {
    return route.params.id + route.query.model
})

const getDict = () => {
    getByDicId({ dicId: 'website_config' }).then((data) => {
        const obj = {}
        data.forEach((item) => {
            obj[item.itemId] = item.paramValue
        })
        systemStoreInfo.websiteConfig = obj
    })
}

getDict()

onMounted(() => {
    nextTick(() => {
        const height = navBar.value.clientHeight + footer.value.clientHeight
        mainHeight.value = `calc(100vh - ${height}px)`
    })
})
</script>

<style lang="scss">
body {
    color: #111111;
}
.navbar {
    height: 64px;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: rgb(147,147,153,0.15);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(147,147,153,0.4);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
  &:hover {
    background-color: rgb(147,147,153,0.6);
  }
}
</style>
