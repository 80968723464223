import { defineStore } from 'pinia'

export const systemStore = defineStore('system', {
    state: () => {
        return {
            loginType: 0, // 用户登录弹框 0不显示 1登录 2注册
            websiteConfig: {
                website_config_company_address: '',
                website_config_company_icp: '',
                website_config_company_logo: '',
                website_config_company_name: '',
                website_config_company_en_name: '',
                website_config_company_qr_code: '',
                website_config_crumb_name: '桂泽仪器'
            }
        }
    }
})
