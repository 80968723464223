import { h, render } from 'vue'
import Message from './index.vue'

const div = document.createElement('div')
div.setAttribute('class', 'msssage-box')
document.body.appendChild(div)

let timer = null

export default ({ type, text, duration = 3000 }) => {
    const vnode = h(Message, { type, text })
    render(vnode, div)
    clearTimeout(timer)
    timer = setTimeout(() => {
        render(null, div)
    }, duration)
}
