import request from '@/utils/request'

// 分页查询分类信息
export function getCategoryPage (data) {
    return request({
        url: '/guize-instrument-server/customer/product/category/page',
        method: 'post',
        data
    })
}
// 查询父级的分类情况(面包屑使用)
export function getCategoryParent (data) {
    return request({
        url: '/guize-instrument-server/customer/product/category/parent',
        method: 'post',
        data
    })
}
// 查询此分类下的搜索项目
export function queryProductSearchParam (data) {
    return request({
        url: '/guize-instrument-server/customer/product/queryProductSearchParam',
        method: 'post',
        data
    })
}
// 分页查产品列表
export function getProductPage (data) {
    return request({
        url: '/guize-instrument-server/customer/product/product/page',
        method: 'post',
        data
    })
}
// 查产品详情
export function getProductDetail (params) {
    return request({
        url: '/guize-instrument-server/customer/product/product/detail',
        method: 'get',
        params
    })
}
// 查询产品下规格列表
export function getProductNorms (params) {
    return request({
        url: '/guize-instrument-server/customer/product/norms/detail/list',
        method: 'get',
        params
    })
}
// 查询规格下型号列表
export function getProductModel (params) {
    return request({
        url: '/guize-instrument-server/customer/product/model/detail/list',
        method: 'get',
        params
    })
}
// 产品搜索接口
export function searchProduct (data) {
    return request({
        url: '/guize-instrument-server/customer/product/searchProduct',
        method: 'post',
        data
    })
}
