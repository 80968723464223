import request from '@/utils/request'

// 轮播图列表
export function getBannerList (data) {
    return request({
        url: '/guize-instrument-server/customer/auxiliary/marketing/list',
        method: 'post',
        data
    })
}

// 获取联系人列表
export function getContactList (params) {
    return request({
        url: '/guize-instrument-server/customer/devops/contact/list',
        method: 'get',
        params
    })
}

// 获取院校列表
export function getSchoolList (params) {
    return request({
        url: '/guize-instrument-server/customer/devops/school/getAllList',
        method: 'get',
        params
    })
}

// 分页查询招聘信息列表
export function recruitingPage (data) {
    return request({
        url: '/guize-instrument-server/customer/devops/recruiting/page',
        method: 'post',
        data
    })
}

// 获取参数列表组
export function getByDicId (params) {
    return request({
        url: '/guize-instrument-server/customer/devops/dictionary/getByDicId',
        method: 'get',
        params
    })
}

// 获取参数详细信息（单个）
export function getByItemId (params) {
    return request({
        url: '/guize-instrument-server/customer/devops/dictionary/getByItemId',
        method: 'get',
        params
    })
}

// 获取参数详细信息（多个）
export function getByItemIds (data) {
    return request({
        url: '/guize-instrument-server/customer/devops/dictionary/getByItemIds',
        method: 'POST',
        data
    })
}
