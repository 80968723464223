import request from './../utils/axios' // 组件内部封装的axios

// 获取验证图片  以及token
export function reqGet (data) {
    return request({
        url: '/guize-instrument-server/customer/auth/getVerifyCode',
        method: 'post',
        data
    })
}

// 滑动或者点选验证
export function reqCheck (data) {
    return request({
        url: '/guize-instrument-server/customer/auth/checkVerifyCode',
        method: 'post',
        data
    })
}
