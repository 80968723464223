<template>
  <div v-show="loginType" class="gz-dialog">
    <div class="gz-dialog_mask"></div>
    <div v-if="!showFill" class="dialog">
      <img class="gz-dialog_close" src="@/assets/icon/close.png" @click="closeDialog">
      <template v-if="!resetType">
        <div class="tab">
          <div class="tab-item" :class="loginType===1?'tab-act':''" @click="loginType=1">
            <div>{{ $t('common.login') }}</div>
            <div class="line"></div>
          </div>
          <div class="tab-item" :class="loginType===2?'tab-act':''" @click="loginType=2">
            <div>{{ $t('common.register') }}</div>
            <div class="line"></div>
          </div>
        </div>
        <!-- 登录表单 -->
        <div v-show="loginType===1" class="wrap">
          <div class="wrap-input">
            <div class="wrap-input-item">
              <div class="label">+86</div>
              <input v-model.number="loginForm.mobile" class="input" :placeholder="$t('common.phoneTip')" />
            </div>
            <div class="wrap-input-item">
              <div class="label">{{ $t('common.password') }}</div>
              <input v-model="loginForm.password" type="password" class="input" :placeholder="$t('common.password')" />
            </div>
            <div class="forget" @click="forgetPassword">{{ $t('common.forgetPassword') }}</div>
          </div>
          <div class="wrap-btn" @click="goLogin">{{ $t('common.login') }}</div>
          <div class="wrap-tip">
            <span>{{ $t('common.loginTip') }}</span>
            <router-link to="/" class="text-theme">{{ $t('common.protocol') }}</router-link>
          </div>
        </div>
        <!-- 注册表单 -->
        <div v-show="loginType===2" class="wrap">
          <div class="wrap-input">
            <div class="wrap-input-item">
              <div class="label">+86</div>
              <input v-model.number="registerForm.mobile" class="input" :placeholder="$t('common.phoneTip')" />
              <div v-if="countDownSec < 1" class="code" :class="registerForm.mobile ? 'code_act' : ''" @click="showVerify">{{ $t('common.getCode') }}</div>
              <div v-else class="code">{{ countDownSec + $t('common.codeCountDown') }}</div>
            </div>
            <div class="wrap-input-item">
              <div class="label">{{ $t('common.code') }}</div>
              <input v-model="registerForm.smsCode" class="input" :placeholder="$t('common.codeTip')" />
            </div>
            <div class="wrap-input-item">
              <div class="label">{{ $t('common.password') }}</div>
              <input v-model="registerForm.password" type="password" class="input" :placeholder="$t('common.password')" />
            </div>
          </div>
          <div class="wrap-btn" @click="goRegister">{{ $t('common.register') }}</div>
          <div class="wrap-tip">
            <span>{{ $t('common.loginTip') }}</span>
            <router-link to="/" class="text-theme">{{ $t('common.protocol') }}</router-link>
          </div>
        </div>
      </template>
      <template v-else>
        <img v-show="loginType!==3 || resetType===2" class="i-back" src="@/assets/icon/back.png" @click="resetBack">
        <div class="tab">重置密码</div>
        <!-- 重置密码-获取验证码 -->
        <div v-show="resetType===1" class="wrap">
          <div class="wrap-input">
            <div class="wrap-input-item">
              <div class="label">+86</div>
              <input v-model.number="resetForm.mobile" class="input" :placeholder="$t('common.phoneTip')" />
              <div v-if="countDownSec < 1" class="code" :class="resetForm.mobile ? 'code_act' : ''" @click="showVerify">{{ $t('common.getCode') }}</div>
              <div v-else class="code">{{ countDownSec + $t('common.codeCountDown') }}</div>
            </div>
            <div class="wrap-input-item">
              <div class="label">{{ $t('common.code') }}</div>
              <input v-model="resetForm.smsCode" class="input" :placeholder="$t('common.codeTip')" />
            </div>
          </div>
          <div class="wrap-btn" @click="setPassword">{{ $t('common.confirm') }}</div>
        </div>
        <!-- 重置密码-二次确认密码 -->
        <div v-show="resetType===2" class="wrap">
          <div class="wrap-input">
            <div class="wrap-input-item">
              <div class="label80">{{ $t('common.password') }}</div>
              <input v-model="resetForm.password" type="password" class="input" :placeholder="$t('common.setPasswordTip')" />
            </div>
            <div class="wrap-input-item">
              <div class="label80">{{ $t('common.confirmPassword') }}</div>
              <input v-model="resetForm.password2" type="password" class="input" :placeholder="$t('common.setPasswordTip')" />
            </div>
          </div>
          <div class="wrap-btn" @click="resetPassword">{{ $t('common.confirm') }}</div>
        </div>
      </template>
    </div>
    <!-- 完善资料 注册后弹出 -->
    <div v-if="showFill" class="dialog dialog_info">
      <img class="gz-dialog_close" src="@/assets/icon/close.png" @click="closeDialog">
      <div class="tab">完善资料</div>
      <div class="wrap">
        <div class="wrap-input">
          <div class="wrap-input-item">
            <div class="label80">{{ $t('common.workPlaceName') }}</div>
            <input v-model="areaForm.companyName" class="input" :placeholder="$t('common.workPlaceName')" />
          </div>
          <div class="wrap-input-item" style="padding-bottom: 6px;">
            <div class="label80">{{ $t('common.workPlaceAddress') }}</div>
            <div class="area">
              <select v-model="areaForm.provinceCode" class="select" id="province" @change="selectProvince">
                <option selected disabled value="">省</option>
                <option v-for="(item, index) in provinceList" :key="index" :value="item.code">{{ item.value }}</option>
              </select>
              <select v-model="areaForm.cityCode" class="select" id="city" @change="selectCity">
                <option selected disabled value="">市</option>
                <option v-for="(item, index) in cityList" :key="index" :value="item.code">{{ item.value }}</option>
              </select>
              <select v-model="areaForm.areaCode" class="select" id="area" @change="selectArea">
                <option selected disabled value="">区</option>
                <option v-for="(item, index) in areaList" :key="index" :value="item.code">{{ item.value }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="wrap-btn" @click="submitInfo">{{ $t('common.confirm') }}</div>
      </div>
    </div>
    <Verify
      v-if="isShowVerify"
      ref="verify"
      mode="pop"
      captchaType="blockPuzzle"
      :imgSize="{ width: '400px', height: '200px' }"
      @success="verifySuccess"
    ></Verify>
  </div>
</template>

<script setup>
import { ref, nextTick, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { systemStore } from '@/store/system.js'
import { userStore } from '@/store/user.js'
import { getMobileCode, mobileRegister, mobileLogin, passwordReset, updateUserDetail } from '@/api/user.js'
import { verifyForm, cryptoCode } from '@/utils/common.js'
import Verify from '@/components/verifition/Verify.vue'
import area from '@/utils/area'
import Message from '@/components/message/index.js'

const refs = storeToRefs(systemStore())
const loginType = refs.loginType // 1登录 2注册 3重置密码
const userStoreInfo = userStore()
const resetType = ref(0) // 0注册登录 1重置密码-获取验证码 2重置密码-确认密码
const isShowVerify = ref(false)
const loginForm = ref({
    mobile: '',
    password: ''
})
const registerForm = ref({
    mobile: '',
    smsCode: '',
    password: ''
})
const areaForm = ref({
    companyName: '',
    provinceCode: null,
    provinceName: '',
    cityCode: null,
    cityName: '',
    areaCode: null,
    areaName: ''
})
const resetForm = ref({
    mobile: '',
    smsCode: '',
    password: '',
    password2: ''
})
const verify = ref(null)
const countDownSec = ref(0)
const timer = ref(null)
const showFill = ref(false)
const provinceList = area
const cityList = ref([])
const areaList = ref([])

const showVerify = () => {
    isShowVerify.value = true
    nextTick(() => {
        verify.value.show()
    })
}
const verifySuccess = ({ captchaVerification }) => {
    if (!resetType.value && loginType.value === 1) { // 登录
        mobileLogin({
            mobile: loginForm.value.mobile,
            password: cryptoCode(loginForm.value.password),
            verifyCode: captchaVerification
        }).then((data) => {
            closeDialog()
            loginSuccess(data)
        })
    } else { // 手机号获取验证码
        countDown(captchaVerification)
    }
}
const countDown = (captchaVerification) => {
    isShowVerify.value = false
    let mobile = registerForm.value.mobile
    if (resetType.value) {
        mobile = resetForm.value.mobile
    }
    getMobileCode({
        mobile: mobile,
        verifyCode: captchaVerification
    })
    countDownSec.value = 60
    timer.value = setInterval(() => {
        if (countDownSec.value > 0) {
            countDownSec.value--
        } else {
            clearInterval(timer.value)
        }
    }, 1000)
}
const resetCountDown = () => {
    clearInterval(timer.value)
    countDownSec.value = 0
}
const forgetPassword = () => {
    resetType.value = 1
    resetCountDown()
}
const resetFormData = () => {
    loginForm.value = {
        mobile: '',
        password: ''
    }
    registerForm.value = {
        mobile: '',
        smsCode: '',
        password: ''
    }
    areaForm.value = {
        companyName: '',
        provinceCode: null,
        provinceName: '',
        cityCode: null,
        cityName: '',
        areaCode: null,
        areaName: ''
    }
    resetForm.value = {
        mobile: '',
        smsCode: '',
        password: '',
        password2: ''
    }
}
const closeDialog = () => {
    loginType.value = 0
    resetType.value = 0
    showFill.value = false
    resetCountDown()
    resetFormData()
}
const resetBack = () => {
    resetType.value--
    if (!resetType.value) resetCountDown()
}
const setPassword = () => {
    verifyForm([
        { key: 'mobile', message: '请输入手机号' },
        { key: 'smsCode', message: '请输入验证码' }
    ], resetForm.value).then(() => {
        resetType.value = 2
    })
}
const resetPassword = () => {
    if (!resetForm.value.password || !resetForm.value.password2) {
        Message({
            type: 'warning',
            text: '请输入密码'
        })
        return
    }
    if (resetForm.value.password !== resetForm.value.password2) {
        Message({
            type: 'warning',
            text: '密码输入不一致'
        })
        return
    }
    passwordReset(resetForm.value).then(() => {
        closeDialog()
        Message({
            type: 'success',
            text: '密码重置成功'
        })
    })
}
const goLogin = () => {
    verifyForm([
        { key: 'mobile', message: '请输入手机号' },
        { key: 'password', message: '请输入密码' }
    ], loginForm.value).then(() => {
        showVerify()
    })
}
const goRegister = () => {
    cryptoCode(registerForm.value.password)
    verifyForm([
        { key: 'mobile', message: '请输入手机号' },
        { key: 'smsCode', message: '请输入验证码' },
        { key: 'password', message: '请输入密码' }
    ], registerForm.value).then(() => {
        mobileRegister({
            ...registerForm.value,
            password: cryptoCode(registerForm.value.password)
        }).then((data) => {
            loginSuccess(data)
            // 注册成功弹出信息完善框
            showFill.value = true
        })
    })
}
const loginSuccess = ({ token, userInfo }) => {
    Message({
        type: 'success',
        text: '登录成功'
    })
    userStoreInfo.token = token
    userStoreInfo.userInfo = userInfo
    userStoreInfo.getUserCommon()
    localStorage.setItem('TOKEN', token)
    localStorage.setItem('USERINFO', JSON.stringify(userInfo))
}
const selectProvince = () => {
    const index = provinceList.findIndex(it => it.code === areaForm.value.provinceCode)
    cityList.value = provinceList[index].children
    areaForm.value.provinceName = provinceList[index].value
    areaForm.value.cityCode = null
    areaForm.value.cityName = ''
}
const selectCity = () => {
    const index = cityList.value.findIndex(it => it.code === areaForm.value.cityCode)
    areaList.value = cityList.value[index].children
    areaForm.value.cityName = cityList.value[index].value
    areaForm.value.areaCode = null
    areaForm.value.areaName = ''
}
const selectArea = () => {
    const index = areaList.value.findIndex(it => it.code === areaForm.value.areaCode)
    areaForm.value.areaName = areaList.value[index].value
}
// 完善资料提交
const submitInfo = () => {
    for (const i in areaForm.value) {
        if (!areaForm.value[i]) {
            Message({
                type: 'warning',
                text: '请将表单内容填写完整'
            })
            return
        }
    }
    updateUserDetail(areaForm.value).then(() => {
        Message({
            type: 'success',
            text: '资料完善成功'
        })
        closeDialog()
    })
}

watch(() => loginType.value, (val) => {
    if (val === 3) { // 展示重置密码弹框
        resetType.value = 1
    }
})
</script>

<style lang="scss" scoped>
.dialog {
  position: relative;
  width: 520px;
  height: 466px;
  padding-top: 58px;
  background: url('~@/assets/images/bg-login.png') no-repeat;
  background-size: cover;
}
.i-back {
  position: absolute;
  top: 18px;
  left: 18px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.tab {
  display: flex;
  justify-content: center;
  gap: 0 62px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  &-item {
    position: relative;
    height: 28px;
    cursor: pointer;
    .line {
      position: absolute;
      left: 50%;
      top: 29px;
      width: 0;
      height: 3px;
      transform: translateX(-50%);
      background: #D9312D;
      border-radius: 2px;
      transition: width 0.15s ease-in-out;
    }
  }
  &-act {
    color: #D9312D;
    .line {
      width: 24px;
    }
  }
}
.wrap-input {
  width: 326px;
  height: 242px;
  margin: 28px auto 0;
  overflow: hidden;
  &-item {
    display: flex;
    align-items: center;
    height: 50px;
    margin-top: 14px;
    border-bottom: 1px solid #f1f1f1;
    font-size: 16px;
    line-height: 22px;
    .label {
      width: 68px;
    }
    .label80 {
      width: 80px;
    }
    .input {
      flex: 1;
      width: 0;
      border: none;
      font-size: 16px;
    }
  }
  .forget {
    margin-top: 13px;
    text-align: right;
    font-size: 12px;
    line-height: 17px;
    cursor: pointer;
  }
  .code {
    height: 27px;
    padding: 0 10px;
    background: #EEEEEF;
    border: 1px solid #EEEEEF;
    border-radius: 14px;
    pointer-events: none;
    color: #a9a9a9;
    font-weight: 500;
    line-height: 27px;
    font-size: 12px;
  }
  .code_act {
    background: #FFFFFF;
    border-color: #D9312D;
    pointer-events: auto;
    cursor: pointer;
    color: #D9312D;
  }
}
.wrap-btn {
  width: 314px;
  height: 42px;
  margin: 0 auto;
  background: #D9312D;
  border-radius: 21px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 42px;
}
.wrap-tip {
  display: none;
  margin-top: 12px;
  text-align: center;
  font-size: 10px;
  line-height: 14px;
  color: #8c8c8c;
}
.dialog_info {
  width: 664px;
  height: 377px;
  background-image: url('~@/assets/images/bg-info.png');
  .wrap-input {
    width: 510px;
    height: 170px;
  }
}
.area {
  display: flex;
  gap: 0 14px;
  .select {
    width: 135px;
    height: 44px;
    padding: 0 10px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    font-size: 16px;
    line-height: 42px;
  }
}
</style>
