<template>
  <div class="side">
    <div class="side-item" @click="show=true">
      <img class="icon" src="@/assets/icon/service.png">
    </div>
    <router-link to="/cart" class="side-item">
      <img class="icon" src="@/assets/icon/cart.png">
      <div class="badge">{{ store.cartGoodsNum }}</div>
    </router-link>
    <router-link to="/compare" class="side-item">
      <img class="icon" src="@/assets/icon/vs.png">
      <div class="badge">{{ store.compareNum }}</div>
    </router-link>
    <router-link to="/mypage" class="side-item">
      <img class="icon" src="@/assets/icon/mypage.png">
    </router-link>
    <div v-show="show" class="gz-dialog">
      <div class="gz-dialog_mask"></div>
      <div class="dialog">
        <img class="gz-dialog_close" src="@/assets/icon/close.png" @click="show=false">
        <div class="gz-dialog_header text-center">{{ $t('common.service') }}</div>
        <div class="text-center">
          <img class="qrcode" :src="qrCode">
          <div class="tip">请使用微信手机端扫码联系客服</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { userStore } from '@/store/user'
import { systemStore } from '@/store/system.js'

const store = userStore()
const show = ref(false)
const qrCode = computed(() => {
    return systemStore().websiteConfig.website_config_company_qr_code
})

store.getUserCommon()
store.getCompareData()
</script>

<style lang="scss" scoped>
.side {
  position: fixed;
  top: 50vh;
  right: 0;
  z-index: 9;
  &-item {
    position: relative;
    display: block;
    width: 56px;
    height: 40px;
    margin-bottom: 4px;
    background: #414142;
    border-radius: 20px 0px 0px 20px;
    &:hover {
      background: #D9312D;
    }
    .icon {
      width: 18px;
      height: 18px;
      margin-top: 11px;
      margin-left: 22px;
    }
    .badge {
      position: absolute;
      top: 3px;
      left: 14px;
      min-width: 16px;
      height: 16px;
      padding: 0 3px;
      border-radius: 7px;
      background: #EE3232;
      text-align: center;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 16px;
    }
  }
}
.dialog {
  position: relative;
  width: 480px;
  padding-bottom: 46px;
  background: #FFFFFF;
  border-radius: 10px;
  overflow: hidden;
  .qrcode {
    width: 130px;
    height: 130px;
    margin: 46px auto 0;
  }
  .tip {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    line-height: 22px;
  }
}
</style>
