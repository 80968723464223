export default {
    common: {
        name: '桂泽仪器',
        login: 'login',
        register: 'register'
    },
    footer: {
        category: '品类齐全,轻松选购',
        quality: '正品保障,质量保证',
        transport: '顺丰发货,疾速送达',
        aftersales: '无忧售后,全程放心',
        contact: '联系我们',
        wechat: '企业微信'
    },
    home: {
        product: 'product',
        company: 'company',
        aboutus: 'aboutus',
        search: 'search',
        productNew: 'new',
        cooperation: 'cooperation'
    }
}
