export default {
    common: {
        name: '桂泽仪器',
        login: '登录',
        register: '注册',
        password: '密码',
        code: '验证码',
        confirm: '确定',
        cancel: '取消',
        modify: '修改',
        delete: '删除',
        default: '默认',
        setDefault: '设为默认',
        forgetPassword: '忘记密码?',
        confirmPassword: '确认密码',
        protocol: '《平台用户协议》',
        phoneTip: '请输入手机号',
        loginTip: '注册登录即代表同意',
        codeTip: '请输入验证码',
        setPasswordTip: '设置新密码',
        getCode: '获取验证码',
        codeCountDown: '秒重新获取',
        workPlaceName: '单位名称',
        workPlaceAddress: '所在地址',
        more: '更多',
        retract: '收起',
        noRecord: '暂无记录',
        noData: '暂无数据',
        service: '联系客服'
    },
    footer: {
        category: '品类齐全,轻松选购',
        quality: '正品保障,质量保证',
        transport: '顺丰发货,疾速送达',
        aftersales: '无忧售后,全程放心',
        contact: '联系我们',
        wechat: '企业微信'
    },
    home: {
        product: '产品中心',
        company: '企业介绍',
        contact: '联系我们',
        aboutUs: '关于我们',
        joinUs: '加入我们',
        search: '产品名称/型号',
        userCenter: '个人中心',
        modifyPassword: '修改密码',
        loginOut: '退出登录',
        productNew: '产品上新',
        cooperation: '合作院校',
        work: '招聘岗位',
        salary: '年薪',
        major: '专业要求',
        education: '学历要求',
        office: '办公地点'
    },
    cart: {
        cart: '购物车',
        clearCart: '清空购物车',
        all: '全部',
        goods: '商品',
        unitPrice: '单价',
        discountPrice: '折扣单价',
        num: '数量',
        subtotal: '小计',
        action: '操作',
        deleted: '删除',
        total: '总计',
        already: '已选择',
        alreadyPro: '件产品',
        quotation: '生成报价单',
        submit: '提交订单',
        confirmOrder: '确认订单',
        address: '收货地址',
        chooseAddress: '选择地址',
        changeAddress: '更换地址',
        orderInfo: '订单信息',
        productInfo: '产品信息',
        productModel: '产品型号',
        price: '价格',
        model: '型号',
        invoice: '发票信息',
        invoiceType: '发票类型',
        addInvoice: '新增发票信息',
        invoiceEmail: '电子发票寄送邮箱',
        invoicePost: '发票寄送',
        postWithGoods: '随货寄出',
        postSelf: '单独寄送',
        orderRemark: '订单备注',
        toSubmit: '确认提交',
        placeholderEmail: '请输入接收邮箱',
        placeholderRemark: '请输入备注信息',
        submitSuccess: '订单已提交',
        submitTip: '我们会尽快联系您，预计1小时以内与您确认报价单，请稍后^_^',
        getContract: '下载合同',
        createTime: '创建时间',
        orderPrice: '订单金额',
        orderPay: '支付金额',
        orderTip: '我们会在第一时间联系您，请稍等片刻'
    },
    mypage: {
        personalCenter: '个人中心',
        personalInfo: '个人信息',
        address: '地址管理',
        addAddress: '新增地址',
        myAddress: '我的地址',
        invoice: '发票管理',
        editInfo: '编辑资料',
        advisor: '专属顾问',
        orderRecord: '订单记录',
        orderNo: '订单编号',
        orderStatus: '订单状态',
        orderDetail: '订单详情',
        prev: '上一页',
        next: '下一页',
        total: '共',
        page: '页',
        jump: '跳至',
        name: '姓名',
        holderName: '请输入您的姓名',
        email: '邮箱',
        holderEmail: '请输入邮箱',
        company: '单位',
        holderCompany: '请输入单位名称',
        companyAddress: '单位所在地区'
    },
    product: {
        description: '产品说明',
        photo: '示意图',
        video: '演示视频',
        parameters: '通用参数',
        addCart: '加入购物车',
        contrast: '对比',
        pdf: 'PDF图纸',
        step: 'STEP模型',
        book: '实验手册',
        chooseTip: '已选{num}个商品',
        compare: '产品对比',
        holderModel: '请输入产品型号',
        addCompare: '添加'
    },
    invoice: {
        company: '单位',
        personal: '个人',
        type: '发票类型',
        header: '发票抬头',
        number: '纳税人识别号',
        address: '注册地址',
        phone: '注册电话',
        bank: '开户银行',
        account: '银行账户',
        holderHeader: '请输入发票抬头',
        holderNumber: '请输入纳税人识别号',
        holderAddress: '增值税专用发票需填写公司地址',
        holderPhone: '增值税专用发票需填写公司电话',
        holderBank: '增值税专用发票需填写公司开户银行',
        holderAccount: '增值税专用发票需填写公司银行账户'
    },
    address: {
        recipient: '收件人',
        phone: '手机号码',
        area: '所在地区',
        detailAddress: '详细地址',
        holderRecipient: '请输入收件人姓名',
        holderPhone: '收件人手机号码',
        holderAddress: '请输入详细地址'
    }
}
